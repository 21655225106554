export default function slideToLoop(
  index = 0,
  speed = this.params.speed,
  runCallbacks = true,
  internal,
) {
  const swiper = this;
  let newIndex = index;
  if (params.loop) {
    newIndex += loopedSlides;
  }

  return slideTo(newIndex, speed, runCallbacks, internal);
}
