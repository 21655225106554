export default function onResize() {
  const swiper = this;

  const { params, el } = swiper;

  if (el && el.offsetWidth === 0) return;

  // Breakpoints
  if (params.breakpoints) {
    setBreakpoint();
  }

  // Save locks
  const { allowSlideNext, allowSlidePrev, snapGrid } = swiper;

  // Disable locks on resize
  allowSlideNext = true;
  allowSlidePrev = true;

  updateSize();
  updateSlides();

  updateSlidesClasses();
  if (
    (params.slidesPerView === 'auto' || params.slidesPerView > 1) &&
    isEnd &&
    !isBeginning &&
    !params.centeredSlides
  ) {
    slideTo(slides.length - 1, 0, false, true);
  } else {
    slideTo(activeIndex, 0, false, true);
  }

  if (autoplay && autoplay.running && autoplay.paused) {
    autoplay.run();
  }
  // Return locks after resize
  allowSlidePrev = allowSlidePrev;
  allowSlideNext = allowSlideNext;

  if (params.watchOverflow && snapGrid !== snapGrid) {
    checkOverflow();
  }
}
