import { now, nextTick } from '../../../utils/utils';

export default function onTouchEnd(event) {
  const swiper = this;
  const data = touchEventsData;

  const { params, touches, rtlTranslate: rtl, $wrapperEl, slidesGrid, snapGrid, enabled } = swiper;
  if (!enabled) return;
  let e = event;
  if (e.originalEvent) e = e.originalEvent;
  if (data.allowTouchCallbacks) {
    emit('touchEnd', e);
  }
  data.allowTouchCallbacks = false;
  if (!data.isTouched) {
    if (data.isMoved && params.grabCursor) {
      setGrabCursor(false);
    }
    data.isMoved = false;
    data.startMoving = false;
    return;
  }
  // Return Grab Cursor
  if (
    params.grabCursor &&
    data.isMoved &&
    data.isTouched &&
    (allowSlideNext === true || allowSlidePrev === true)
  ) {
    setGrabCursor(false);
  }

  // Time diff
  const touchEndTime = now();
  const timeDiff = touchEndTime - data.touchStartTime;

  // Tap, doubleTap, Click
  if (allowClick) {
    updateClickedSlide(e);
    emit('tap click', e);
    if (timeDiff < 300 && touchEndTime - data.lastClickTime < 300) {
      emit('doubleTap doubleClick', e);
    }
  }

  data.lastClickTime = now();
  nextTick(() => {
    if (!destroyed) allowClick = true;
  });

  if (
    !data.isTouched ||
    !data.isMoved ||
    !swipeDirection ||
    touches.diff === 0 ||
    data.currentTranslate === data.startTranslate
  ) {
    data.isTouched = false;
    data.isMoved = false;
    data.startMoving = false;
    return;
  }
  data.isTouched = false;
  data.isMoved = false;
  data.startMoving = false;

  let currentPos;
  if (params.followFinger) {
    currentPos = rtl ? translate : -translate;
  } else {
    currentPos = -data.currentTranslate;
  }

  if (params.cssMode) {
    return;
  }

  if (params.freeMode) {
    if (currentPos < -minTranslate()) {
      slideTo(activeIndex);
      return;
    }
    if (currentPos > -maxTranslate()) {
      if (slides.length < snapGrid.length) {
        slideTo(snapGrid.length - 1);
      } else {
        slideTo(slides.length - 1);
      }
      return;
    }

    if (params.freeModeMomentum) {
      if (data.velocities.length > 1) {
        const lastMoveEvent = data.velocities.pop();
        const velocityEvent = data.velocities.pop();

        const distance = lastMoveEvent.position - velocityEvent.position;
        const time = lastMoveEvent.time - velocityEvent.time;
        velocity = distance / time;
        velocity /= 2;
        if (Math.abs(velocity) < params.freeModeMinimumVelocity) {
          velocity = 0;
        }
        // this implies that the user stopped moving a finger then released.
        // There would be no events with distance zero, so the last event is stale.
        if (time > 150 || now() - lastMoveEvent.time > 300) {
          velocity = 0;
        }
      } else {
        velocity = 0;
      }
      velocity *= params.freeModeMomentumVelocityRatio;

      data.velocities.length = 0;
      let momentumDuration = 1000 * params.freeModeMomentumRatio;
      const momentumDistance = velocity * momentumDuration;

      let newPosition = translate + momentumDistance;
      if (rtl) newPosition = -newPosition;

      let doBounce = false;
      let afterBouncePosition;
      const bounceAmount = Math.abs(velocity) * 20 * params.freeModeMomentumBounceRatio;
      let needsLoopFix;
      if (newPosition < maxTranslate()) {
        if (params.freeModeMomentumBounce) {
          if (newPosition + maxTranslate() < -bounceAmount) {
            newPosition = maxTranslate() - bounceAmount;
          }
          afterBouncePosition = maxTranslate();
          doBounce = true;
          data.allowMomentumBounce = true;
        } else {
          newPosition = maxTranslate();
        }
        if (params.loop && params.centeredSlides) needsLoopFix = true;
      } else if (newPosition > minTranslate()) {
        if (params.freeModeMomentumBounce) {
          if (newPosition - minTranslate() > bounceAmount) {
            newPosition = minTranslate() + bounceAmount;
          }
          afterBouncePosition = minTranslate();
          doBounce = true;
          data.allowMomentumBounce = true;
        } else {
          newPosition = minTranslate();
        }
        if (params.loop && params.centeredSlides) needsLoopFix = true;
      } else if (params.freeModeSticky) {
        let nextSlide;
        for (let j = 0; j < snapGrid.length; j += 1) {
          if (snapGrid[j] > -newPosition) {
            nextSlide = j;
            break;
          }
        }

        if (
          Math.abs(snapGrid[nextSlide] - newPosition) <
            Math.abs(snapGrid[nextSlide - 1] - newPosition) ||
          swipeDirection === 'next'
        ) {
          newPosition = snapGrid[nextSlide];
        } else {
          newPosition = snapGrid[nextSlide - 1];
        }
        newPosition = -newPosition;
      }
      if (needsLoopFix) {
        once('transitionEnd', () => {
          loopFix();
        });
      }
      // Fix duration
      if (velocity !== 0) {
        if (rtl) {
          momentumDuration = Math.abs((-newPosition - translate) / velocity);
        } else {
          momentumDuration = Math.abs((newPosition - translate) / velocity);
        }
        if (params.freeModeSticky) {
          // If freeModeSticky is active and the user ends a swipe with a slow-velocity
          // event, then durations can be 20+ seconds to slide one (or zero!) slides.
          // It's easy to see this when simulating touch with mouse events. To fix this,
          // limit single-slide swipes to the default slide duration. This also has the
          // nice side effect of matching slide speed if the user stopped moving before
          // lifting finger or mouse vs. moving slowly before lifting the finger/mouse.
          // For faster swipes, also apply limits (albeit higher ones).
          const moveDistance = Math.abs((rtl ? -newPosition : newPosition) - translate);
          const currentSlideSize = slidesSizesGrid[activeIndex];
          if (moveDistance < currentSlideSize) {
            momentumDuration = params.speed;
          } else if (moveDistance < 2 * currentSlideSize) {
            momentumDuration = params.speed * 1.5;
          } else {
            momentumDuration = params.speed * 2.5;
          }
        }
      } else if (params.freeModeSticky) {
        slideToClosest();
        return;
      }

      if (params.freeModeMomentumBounce && doBounce) {
        updateProgress(afterBouncePosition);
        setTransition(momentumDuration);
        setTranslate(newPosition);
        transitionStart(true, swipeDirection);
        animating = true;
        $wrapperEl.transitionEnd(() => {
          if (!swiper || destroyed || !data.allowMomentumBounce) return;
          emit('momentumBounce');
          setTransition(params.speed);
          setTimeout(() => {
            setTranslate(afterBouncePosition);
            $wrapperEl.transitionEnd(() => {
              if (!swiper || destroyed) return;
              transitionEnd();
            });
          }, 0);
        });
      } else if (velocity) {
        updateProgress(newPosition);
        setTransition(momentumDuration);
        setTranslate(newPosition);
        transitionStart(true, swipeDirection);
        if (!animating) {
          animating = true;
          $wrapperEl.transitionEnd(() => {
            if (!swiper || destroyed) return;
            transitionEnd();
          });
        }
      } else {
        emit('_freeModeNoMomentumRelease');
        updateProgress(newPosition);
      }

      updateActiveIndex();
      updateSlidesClasses();
    } else if (params.freeModeSticky) {
      slideToClosest();
      return;
    } else if (params.freeMode) {
      emit('_freeModeNoMomentumRelease');
    }

    if (!params.freeModeMomentum || timeDiff >= params.longSwipesMs) {
      updateProgress();
      updateActiveIndex();
      updateSlidesClasses();
    }
    return;
  }

  // Find current slide
  let stopIndex = 0;
  let groupSize = slidesSizesGrid[0];
  for (
    let i = 0;
    i < slidesGrid.length;
    i += i < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup
  ) {
    const increment = i < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;
    if (typeof slidesGrid[i + increment] !== 'undefined') {
      if (currentPos >= slidesGrid[i] && currentPos < slidesGrid[i + increment]) {
        stopIndex = i;
        groupSize = slidesGrid[i + increment] - slidesGrid[i];
      }
    } else if (currentPos >= slidesGrid[i]) {
      stopIndex = i;
      groupSize = slidesGrid[slidesGrid.length - 1] - slidesGrid[slidesGrid.length - 2];
    }
  }

  // Find current slide size
  const ratio = (currentPos - slidesGrid[stopIndex]) / groupSize;
  const increment = stopIndex < params.slidesPerGroupSkip - 1 ? 1 : params.slidesPerGroup;

  if (timeDiff > params.longSwipesMs) {
    // Long touches
    if (!params.longSwipes) {
      slideTo(activeIndex);
      return;
    }
    if (swipeDirection === 'next') {
      if (ratio >= params.longSwipesRatio) slideTo(stopIndex + increment);
      else slideTo(stopIndex);
    }
    if (swipeDirection === 'prev') {
      if (ratio > 1 - params.longSwipesRatio) slideTo(stopIndex + increment);
      else slideTo(stopIndex);
    }
  } else {
    // Short swipes
    if (!params.shortSwipes) {
      slideTo(activeIndex);
      return;
    }
    const isNavButtonTarget =
      navigation &&
      (e.target === navigation.nextEl || e.target === navigation.prevEl);
    if (!isNavButtonTarget) {
      if (swipeDirection === 'next') {
        slideTo(stopIndex + increment);
      }
      if (swipeDirection === 'prev') {
        slideTo(stopIndex);
      }
    } else if (e.target === navigation.nextEl) {
      slideTo(stopIndex + increment);
    } else {
      slideTo(stopIndex);
    }
  }
}
