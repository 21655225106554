import { getWindow } from 'ssr-window';
import { extend } from '../../utils/utils';

const supportsResizeObserver = () => {
  const window = getWindow();
  return typeof window.ResizeObserver !== 'undefined';
};

export default {
  name: 'resize',
  create() {
    const swiper = this;
    extend(swiper, {
      resize: {
        observer: null,
        createObserver() {
          if (!swiper || destroyed || !initialized) return;
          resize.observer = new ResizeObserver((entries) => {
            const { width, height } = swiper;
            let newWidth = width;
            let newHeight = height;
            entries.forEach(({ contentBoxSize, contentRect, target }) => {
              if (target && target !== el) return;
              newWidth = contentRect
                ? contentRect.width
                : (contentBoxSize[0] || contentBoxSize).inlineSize;
              newHeight = contentRect
                ? contentRect.height
                : (contentBoxSize[0] || contentBoxSize).blockSize;
            });
            if (newWidth !== width || newHeight !== height) {
              resize.resizeHandler();
            }
          });
          resize.observer.observe(el);
        },
        removeObserver() {
          if (resize.observer && resize.observer.unobserve && el) {
            resize.observer.unobserve(el);
            resize.observer = null;
          }
        },
        resizeHandler() {
          if (!swiper || destroyed || !initialized) return;
          emit('beforeResize');
          emit('resize');
        },
        orientationChangeHandler() {
          if (!swiper || destroyed || !initialized) return;
          emit('orientationchange');
        },
      },
    });
  },
  on: {
    init(swiper) {
      const window = getWindow();
      if (params.resizeObserver && supportsResizeObserver()) {
        resize.createObserver();
        return;
      }
      // Emit resize
      window.addEventListener('resize', resize.resizeHandler);

      // Emit orientationchange
      window.addEventListener('orientationchange', resize.orientationChangeHandler);
    },
    destroy(swiper) {
      const window = getWindow();
      resize.removeObserver();
      window.removeEventListener('resize', resize.resizeHandler);
      window.removeEventListener('orientationchange', resize.orientationChangeHandler);
    },
  },
};
