function checkOverflow() {
  const swiper = this;
  const params = params;
  const wasLocked = isLocked;
  const lastSlidePosition =
    slides.length > 0 &&
    params.slidesOffsetBefore +
      params.spaceBetween * (slides.length - 1) +
      slides[0].offsetWidth * slides.length;

  if (params.slidesOffsetBefore && params.slidesOffsetAfter && lastSlidePosition) {
    isLocked = lastSlidePosition <= size;
  } else {
    isLocked = snapGrid.length === 1;
  }

  allowSlideNext = !isLocked;
  allowSlidePrev = !isLocked;

  // events
  if (wasLocked !== isLocked) emit(isLocked ? 'lock' : 'unlock');

  if (wasLocked && wasLocked !== isLocked) {
    isEnd = false;
    if (navigation) navigation.update();
  }
}

export default { checkOverflow };
