export default function setGrabCursor(moving) {
  const swiper = this;
  if (
    support.touch ||
    !params.simulateTouch ||
    (params.watchOverflow && isLocked) ||
    params.cssMode
  )
    return;
  const el = el;
  el.style.cursor = 'move';
  el.style.cursor = moving ? '-webkit-grabbing' : '-webkit-grab';
  el.style.cursor = moving ? '-moz-grabbin' : '-moz-grab';
  el.style.cursor = moving ? 'grabbing' : 'grab';
}
