
export default function slideNext(speed = this.params.speed, runCallbacks = true, internal) {
  const swiper = this;
  const { params, animating, enabled } = swiper;
  if (!enabled) return swiper;
  const increment = activeIndex < params.slidesPerGroupSkip ? 1 : params.slidesPerGroup;
  if (params.loop) {
    if (animating && params.loopPreventsSlide) return false;
    loopFix();
    // eslint-disable-next-line
    _clientLeft = $wrapperEl[0].clientLeft;
  }
  return slideTo(activeIndex + increment, speed, runCallbacks, internal);
}
