export default function slideTo(
  index = 0,
  speed = this.params.speed,
  runCallbacks = true,
  internal,
  initial,
) {
  if (typeof index !== 'number' && typeof index !== 'string') {
    throw new Error(
      `The 'index' argument cannot have type other than 'number' or 'string'. [${typeof index}] given.`,
    );
  }

  if (typeof index === 'string') {
    /**
     * The `index` argument converted from `string` to `number`.
     * @type {number}
     */
    const indexAsNumber = parseInt(index, 10);

    /**
     * Determines whether the `index` argument is a valid `number`
     * after being converted from the `string` type.
     * @type {boolean}
     */
    const isValidNumber = isFinite(indexAsNumber);

    if (!isValidNumber) {
      throw new Error(
        `The passed-in 'index' (string) couldn't be converted to 'number'. [${index}] given.`,
      );
    }

    // Knowing that the converted `index` is a valid number,
    // we can update the original argument's value.
    index = indexAsNumber;
  }

  const swiper = this;
  let slideIndex = index;
  if (slideIndex < 0) slideIndex = 0;

  const {
    params,
    snapGrid,
    slidesGrid,
    previousIndex,
    activeIndex,
    rtlTranslate: rtl,
    wrapperEl,
    enabled,
  } = swiper;

  if (
    (animating && params.preventInteractionOnTransition) ||
    (!enabled && !internal && !initial)
  ) {
    return false;
  }

  const skip = Math.min(params.slidesPerGroupSkip, slideIndex);
  let snapIndex = skip + Math.floor((slideIndex - skip) / params.slidesPerGroup);
  if (snapIndex >= snapGrid.length) snapIndex = snapGrid.length - 1;

  if ((activeIndex || params.initialSlide || 0) === (previousIndex || 0) && runCallbacks) {
    emit('beforeSlideChangeStart');
  }

  const translate = -snapGrid[snapIndex];

  // Update progress
  updateProgress(translate);

  // Normalize slideIndex
  if (params.normalizeSlideIndex) {
    for (let i = 0; i < slidesGrid.length; i += 1) {
      const normalizedTranslate = -Math.floor(translate * 100);
      const normalizedGird = Math.floor(slidesGrid[i] * 100);
      const normalizedGridNext = Math.floor(slidesGrid[i + 1] * 100);
      if (typeof slidesGrid[i + 1] !== 'undefined') {
        if (
          normalizedTranslate >= normalizedGird &&
          normalizedTranslate < normalizedGridNext - (normalizedGridNext - normalizedGird) / 2
        ) {
          slideIndex = i;
        } else if (
          normalizedTranslate >= normalizedGird &&
          normalizedTranslate < normalizedGridNext
        ) {
          slideIndex = i + 1;
        }
      } else if (normalizedTranslate >= normalizedGird) {
        slideIndex = i;
      }
    }
  }
  // Directions locks
  if (initialized && slideIndex !== activeIndex) {
    if (
      !allowSlideNext &&
      translate < translate &&
      translate < minTranslate()
    ) {
      return false;
    }
    if (
      !allowSlidePrev &&
      translate > translate &&
      translate > maxTranslate()
    ) {
      if ((activeIndex || 0) !== slideIndex) return false;
    }
  }

  let direction;
  if (slideIndex > activeIndex) direction = 'next';
  else if (slideIndex < activeIndex) direction = 'prev';
  else direction = 'reset';

  // Update Index
  if ((rtl && -translate === translate) || (!rtl && translate === translate)) {
    updateActiveIndex(slideIndex);
    // Update Height
    if (params.autoHeight) {
      updateAutoHeight();
    }
    updateSlidesClasses();
    if (params.effect !== 'slide') {
      setTranslate(translate);
    }
    if (direction !== 'reset') {
      transitionStart(runCallbacks, direction);
      transitionEnd(runCallbacks, direction);
    }
    return false;
  }
  if (params.cssMode) {
    const isH = isHorizontal();
    let t = -translate;
    if (rtl) {
      t = wrapperEl.scrollWidth - wrapperEl.offsetWidth - t;
    }
    if (speed === 0) {
      wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = t;
    } else {
      // eslint-disable-next-line
      if (wrapperEl.scrollTo) {
        wrapperEl.scrollTo({
          [isH ? 'left' : 'top']: t,
          behavior: 'smooth',
        });
      } else {
        wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = t;
      }
    }
    return true;
  }

  if (speed === 0) {
    setTransition(0);
    setTranslate(translate);
    updateActiveIndex(slideIndex);
    updateSlidesClasses();
    emit('beforeTransitionStart', speed, internal);
    transitionStart(runCallbacks, direction);
    transitionEnd(runCallbacks, direction);
  } else {
    setTransition(speed);
    setTranslate(translate);
    updateActiveIndex(slideIndex);
    updateSlidesClasses();
    emit('beforeTransitionStart', speed, internal);
    transitionStart(runCallbacks, direction);
    if (!animating) {
      animating = true;
      if (!onSlideToWrapperTransitionEnd) {
        onSlideToWrapperTransitionEnd = function transitionEnd(e) {
          if (!swiper || destroyed) return;
          if (e.target !== this) return;
          $wrapperEl[0].removeEventListener(
            'transitionend',
            onSlideToWrapperTransitionEnd,
          );
          $wrapperEl[0].removeEventListener(
            'webkitTransitionEnd',
            onSlideToWrapperTransitionEnd,
          );
          onSlideToWrapperTransitionEnd = null;
          delete onSlideToWrapperTransitionEnd;
          transitionEnd(runCallbacks, direction);
        };
      }
      $wrapperEl[0].addEventListener('transitionend', onSlideToWrapperTransitionEnd);
      $wrapperEl[0].addEventListener(
        'webkitTransitionEnd',
        onSlideToWrapperTransitionEnd,
      );
    }
  }

  return true;
}
