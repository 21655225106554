export default function setTranslate(translate, byController) {
  const swiper = this;
  const { rtlTranslate: rtl, params, $wrapperEl, wrapperEl, progress } = swiper;
  let x = 0;
  let y = 0;
  const z = 0;

  if (isHorizontal()) {
    x = rtl ? -translate : translate;
  } else {
    y = translate;
  }

  if (params.roundLengths) {
    x = Math.floor(x);
    y = Math.floor(y);
  }

  if (params.cssMode) {
    wrapperEl[isHorizontal() ? 'scrollLeft' : 'scrollTop'] = isHorizontal() ? -x : -y;
  } else if (!params.virtualTranslate) {
    $wrapperEl.transform(`translate3d(${x}px, ${y}px, ${z}px)`);
  }
  previousTranslate = translate;
  translate = isHorizontal() ? x : y;

  // Check if we need to update progress
  let newProgress;
  const translatesDiff = maxTranslate() - minTranslate();
  if (translatesDiff === 0) {
    newProgress = 0;
  } else {
    newProgress = (translate - minTranslate()) / translatesDiff;
  }
  if (newProgress !== progress) {
    updateProgress(translate);
  }

  emit('setTranslate', translate, byController);
}
