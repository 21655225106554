export default function unsetGrabCursor() {
  const swiper = this;
  if (
    support.touch ||
    (params.watchOverflow && isLocked) ||
    params.cssMode
  ) {
    return;
  }
  el.style.cursor = '';
}
