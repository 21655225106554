import $ from '../../utils/dom';
import {
  extend,
  bindModuleMethods,
  classesToSelector,
  createElementIfNotDefined,
} from '../../utils/utils';

const Pagination = {
  update() {
    // Render || Update Pagination bullets/items
    const swiper = this;
    const rtl = rtl;
    const params = params.pagination;
    if (
      !params.el ||
      !pagination.el ||
      !pagination.$el ||
      pagination.$el.length === 0
    )
      return;
    const slidesLength =
      virtual && params.virtual.enabled
        ? virtual.slides.length
        : slides.length;
    const $el = pagination.$el;
    // Current/Total
    let current;
    const total = params.loop
      ? Math.ceil((slidesLength - loopedSlides * 2) / params.slidesPerGroup)
      : snapGrid.length;
    if (params.loop) {
      current = Math.ceil(
        (activeIndex - loopedSlides) / params.slidesPerGroup,
      );
      if (current > slidesLength - 1 - loopedSlides * 2) {
        current -= slidesLength - loopedSlides * 2;
      }
      if (current > total - 1) current -= total;
      if (current < 0 && params.paginationType !== 'bullets') current = total + current;
    } else if (typeof snapIndex !== 'undefined') {
      current = snapIndex;
    } else {
      current = activeIndex || 0;
    }
    // Types
    if (
      params.type === 'bullets' &&
      pagination.bullets &&
      pagination.bullets.length > 0
    ) {
      const bullets = pagination.bullets;
      let firstIndex;
      let lastIndex;
      let midIndex;
      if (params.dynamicBullets) {
        pagination.bulletSize = bullets
          .eq(0)
          [isHorizontal() ? 'outerWidth' : 'outerHeight'](true);
        $el.css(
          isHorizontal() ? 'width' : 'height',
          `${pagination.bulletSize * (params.dynamicMainBullets + 4)}px`,
        );
        if (params.dynamicMainBullets > 1 && previousIndex !== undefined) {
          pagination.dynamicBulletIndex += current - previousIndex;
          if (pagination.dynamicBulletIndex > params.dynamicMainBullets - 1) {
            pagination.dynamicBulletIndex = params.dynamicMainBullets - 1;
          } else if (pagination.dynamicBulletIndex < 0) {
            pagination.dynamicBulletIndex = 0;
          }
        }
        firstIndex = current - pagination.dynamicBulletIndex;
        lastIndex = firstIndex + (Math.min(bullets.length, params.dynamicMainBullets) - 1);
        midIndex = (lastIndex + firstIndex) / 2;
      }
      bullets.removeClass(
        `${params.bulletActiveClass} ${params.bulletActiveClass}-next ${params.bulletActiveClass}-next-next ${params.bulletActiveClass}-prev ${params.bulletActiveClass}-prev-prev ${params.bulletActiveClass}-main`,
      );
      if ($el.length > 1) {
        bullets.each((bullet) => {
          const $bullet = $(bullet);
          const bulletIndex = $bullet.index();
          if (bulletIndex === current) {
            $bullet.addClass(params.bulletActiveClass);
          }
          if (params.dynamicBullets) {
            if (bulletIndex >= firstIndex && bulletIndex <= lastIndex) {
              $bullet.addClass(`${params.bulletActiveClass}-main`);
            }
            if (bulletIndex === firstIndex) {
              $bullet
                .prev()
                .addClass(`${params.bulletActiveClass}-prev`)
                .prev()
                .addClass(`${params.bulletActiveClass}-prev-prev`);
            }
            if (bulletIndex === lastIndex) {
              $bullet
                .next()
                .addClass(`${params.bulletActiveClass}-next`)
                .next()
                .addClass(`${params.bulletActiveClass}-next-next`);
            }
          }
        });
      } else {
        const $bullet = bullets.eq(current);
        const bulletIndex = $bullet.index();
        $bullet.addClass(params.bulletActiveClass);
        if (params.dynamicBullets) {
          const $firstDisplayedBullet = bullets.eq(firstIndex);
          const $lastDisplayedBullet = bullets.eq(lastIndex);
          for (let i = firstIndex; i <= lastIndex; i += 1) {
            bullets.eq(i).addClass(`${params.bulletActiveClass}-main`);
          }
          if (params.loop) {
            if (bulletIndex >= bullets.length - params.dynamicMainBullets) {
              for (let i = params.dynamicMainBullets; i >= 0; i -= 1) {
                bullets.eq(bullets.length - i).addClass(`${params.bulletActiveClass}-main`);
              }
              bullets
                .eq(bullets.length - params.dynamicMainBullets - 1)
                .addClass(`${params.bulletActiveClass}-prev`);
            } else {
              $firstDisplayedBullet
                .prev()
                .addClass(`${params.bulletActiveClass}-prev`)
                .prev()
                .addClass(`${params.bulletActiveClass}-prev-prev`);
              $lastDisplayedBullet
                .next()
                .addClass(`${params.bulletActiveClass}-next`)
                .next()
                .addClass(`${params.bulletActiveClass}-next-next`);
            }
          } else {
            $firstDisplayedBullet
              .prev()
              .addClass(`${params.bulletActiveClass}-prev`)
              .prev()
              .addClass(`${params.bulletActiveClass}-prev-prev`);
            $lastDisplayedBullet
              .next()
              .addClass(`${params.bulletActiveClass}-next`)
              .next()
              .addClass(`${params.bulletActiveClass}-next-next`);
          }
        }
      }
      if (params.dynamicBullets) {
        const dynamicBulletsLength = Math.min(bullets.length, params.dynamicMainBullets + 4);
        const bulletsOffset =
          (pagination.bulletSize * dynamicBulletsLength - pagination.bulletSize) / 2 -
          midIndex * pagination.bulletSize;
        const offsetProp = rtl ? 'right' : 'left';
        bullets.css(isHorizontal() ? offsetProp : 'top', `${bulletsOffset}px`);
      }
    }
    if (params.type === 'fraction') {
      $el
        .find(classesToSelector(params.currentClass))
        .text(params.formatFractionCurrent(current + 1));
      $el.find(classesToSelector(params.totalClass)).text(params.formatFractionTotal(total));
    }
    if (params.type === 'progressbar') {
      let progressbarDirection;
      if (params.progressbarOpposite) {
        progressbarDirection = isHorizontal() ? 'vertical' : 'horizontal';
      } else {
        progressbarDirection = isHorizontal() ? 'horizontal' : 'vertical';
      }
      const scale = (current + 1) / total;
      let scaleX = 1;
      let scaleY = 1;
      if (progressbarDirection === 'horizontal') {
        scaleX = scale;
      } else {
        scaleY = scale;
      }
      $el
        .find(classesToSelector(params.progressbarFillClass))
        .transform(`translate3d(0,0,0) scaleX(${scaleX}) scaleY(${scaleY})`)
        .transition(params.speed);
    }
    if (params.type === 'custom' && params.renderCustom) {
      $el.html(params.renderCustom(swiper, current + 1, total));
      emit('paginationRender', $el[0]);
    } else {
      emit('paginationUpdate', $el[0]);
    }
    if (params.watchOverflow && enabled) {
      $el[isLocked ? 'addClass' : 'removeClass'](params.lockClass);
    }
  },
  render() {
    // Render Container
    const swiper = this;
    const params = params.pagination;
    if (
      !params.el ||
      !pagination.el ||
      !pagination.$el ||
      pagination.$el.length === 0
    )
      return;
    const slidesLength =
      virtual && params.virtual.enabled
        ? virtual.slides.length
        : slides.length;

    const $el = pagination.$el;
    let paginationHTML = '';
    if (params.type === 'bullets') {
      let numberOfBullets = params.loop
        ? Math.ceil((slidesLength - loopedSlides * 2) / params.slidesPerGroup)
        : snapGrid.length;
      if (params.freeMode && !params.loop && numberOfBullets > slidesLength) {
        numberOfBullets = slidesLength;
      }
      for (let i = 0; i < numberOfBullets; i += 1) {
        if (params.renderBullet) {
          paginationHTML += params.renderBullet.call(swiper, i, params.bulletClass);
        } else {
          paginationHTML += `<${params.bulletElement} class="${params.bulletClass}"></${params.bulletElement}>`;
        }
      }
      $el.html(paginationHTML);

      pagination.bullets = $el.find(classesToSelector(params.bulletClass));
    }
    if (params.type === 'fraction') {
      if (params.renderFraction) {
        paginationHTML = params.renderFraction.call(swiper, params.currentClass, params.totalClass);
      } else {
        paginationHTML =
          `<span class="${params.currentClass}"></span>` +
          ' / ' +
          `<span class="${params.totalClass}"></span>`;
      }
      $el.html(paginationHTML);
    }
    if (params.type === 'progressbar') {
      if (params.renderProgressbar) {
        paginationHTML = params.renderProgressbar.call(swiper, params.progressbarFillClass);
      } else {
        paginationHTML = `<span class="${params.progressbarFillClass}"></span>`;
      }
      $el.html(paginationHTML);
    }
    if (params.type !== 'custom') {
      emit('paginationRender', pagination.$el[0]);
    }
  },
  init() {
    const swiper = this;
    params.pagination = createElementIfNotDefined(
      $el,
      params.pagination,
      params.createElements,
      { el: 'swiper-pagination' },
    );
    const params = params.pagination;
    if (!params.el) return;

    let $el = $(params.el);
    if ($el.length === 0) return;

    if (params.uniqueNavElements && typeof params.el === 'string' && $el.length > 1) {
      $el = $el.find(params.el);
    }

    if (params.type === 'bullets' && params.clickable) {
      $el.addClass(params.clickableClass);
    }

    $el.addClass(params.modifierClass + params.type);

    if (params.type === 'bullets' && params.dynamicBullets) {
      $el.addClass(`${params.modifierClass}${params.type}-dynamic`);
      pagination.dynamicBulletIndex = 0;
      if (params.dynamicMainBullets < 1) {
        params.dynamicMainBullets = 1;
      }
    }
    if (params.type === 'progressbar' && params.progressbarOpposite) {
      $el.addClass(params.progressbarOppositeClass);
    }

    if (params.clickable) {
      $el.on('click', classesToSelector(params.bulletClass), function onClick(e) {
        e.preventDefault();
        let index = $(this).index() * params.slidesPerGroup;
        if (params.loop) index += loopedSlides;
        slideTo(index);
      });
    }

    extend(pagination, {
      $el,
      el: $el[0],
    });

    if (!enabled) {
      $el.addClass(params.lockClass);
    }
  },
  destroy() {
    const swiper = this;
    const params = params.pagination;
    if (
      !params.el ||
      !pagination.el ||
      !pagination.$el ||
      pagination.$el.length === 0
    )
      return;
    const $el = pagination.$el;

    $el.removeClass(params.hiddenClass);
    $el.removeClass(params.modifierClass + params.type);
    if (pagination.bullets) pagination.bullets.removeClass(params.bulletActiveClass);
    if (params.clickable) {
      $el.off('click', classesToSelector(params.bulletClass));
    }
  },
};

export default {
  name: 'pagination',
  params: {
    pagination: {
      el: null,
      bulletElement: 'span',
      clickable: false,
      hideOnClick: false,
      renderBullet: null,
      renderProgressbar: null,
      renderFraction: null,
      renderCustom: null,
      progressbarOpposite: false,
      type: 'bullets', // 'bullets' or 'progressbar' or 'fraction' or 'custom'
      dynamicBullets: false,
      dynamicMainBullets: 1,
      formatFractionCurrent: (number) => number,
      formatFractionTotal: (number) => number,
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      modifierClass: 'swiper-pagination-', // NEW
      currentClass: 'swiper-pagination-current',
      totalClass: 'swiper-pagination-total',
      hiddenClass: 'swiper-pagination-hidden',
      progressbarFillClass: 'swiper-pagination-progressbar-fill',
      progressbarOppositeClass: 'swiper-pagination-progressbar-opposite',
      clickableClass: 'swiper-pagination-clickable', // NEW
      lockClass: 'swiper-pagination-lock',
    },
  },
  create() {
    const swiper = this;
    bindModuleMethods(swiper, {
      pagination: {
        dynamicBulletIndex: 0,
        ...Pagination,
      },
    });
  },
  on: {
    init(swiper) {
      pagination.init();
      pagination.render();
      pagination.update();
    },
    activeIndexChange(swiper) {
      if (params.loop) {
        pagination.update();
      } else if (typeof snapIndex === 'undefined') {
        pagination.update();
      }
    },
    snapIndexChange(swiper) {
      if (!params.loop) {
        pagination.update();
      }
    },
    slidesLengthChange(swiper) {
      if (params.loop) {
        pagination.render();
        pagination.update();
      }
    },
    snapGridLengthChange(swiper) {
      if (!params.loop) {
        pagination.render();
        pagination.update();
      }
    },
    destroy(swiper) {
      pagination.destroy();
    },
    'enable disable': (swiper) => {
      const { $el } = pagination;
      if ($el) {
        $el[enabled ? 'removeClass' : 'addClass'](params.pagination.lockClass);
      }
    },
    click(swiper, e) {
      const targetEl = e.target;
      if (
        params.pagination.el &&
        params.pagination.hideOnClick &&
        pagination.$el.length > 0 &&
        !$(targetEl).hasClass(params.pagination.bulletClass)
      ) {
        if (
          navigation &&
          ((navigation.nextEl && targetEl === navigation.nextEl) ||
            (navigation.prevEl && targetEl === navigation.prevEl))
        )
          return;
        const isHidden = pagination.$el.hasClass(params.pagination.hiddenClass);
        if (isHidden === true) {
          emit('paginationShow');
        } else {
          emit('paginationHide');
        }
        pagination.$el.toggleClass(params.pagination.hiddenClass);
      }
    },
  },
};
