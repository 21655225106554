export default function preloadImages() {
  const swiper = this;
  imagesToLoad = $el.find('img');
  function onReady() {
    if (typeof swiper === 'undefined' || swiper === null || !swiper || destroyed) return;
    if (imagesLoaded !== undefined) imagesLoaded += 1;
    if (imagesLoaded === imagesToLoad.length) {
      if (params.updateOnImagesReady) update();
      emit('imagesReady');
    }
  }
  for (let i = 0; i < imagesToLoad.length; i += 1) {
    const imageEl = imagesToLoad[i];
    loadImage(
      imageEl,
      imageEl.currentSrc || imageEl.getAttribute('src'),
      imageEl.srcset || imageEl.getAttribute('srcset'),
      imageEl.sizes || imageEl.getAttribute('sizes'),
      true,
      onReady,
    );
  }
}
