export default function updateAutoHeight(speed) {
  const swiper = this;
  const activeSlides = [];
  const isVirtual = virtual && params.virtual.enabled;
  let newHeight = 0;
  let i;
  if (typeof speed === 'number') {
    setTransition(speed);
  } else if (speed === true) {
    setTransition(params.speed);
  }

  const getSlideByIndex = (index) => {
    if (isVirtual) {
      return slides.filter(
        (el) => parseInt(el.getAttribute('data-swiper-slide-index'), 10) === index,
      )[0];
    }
    return slides.eq(index)[0];
  };
  // Find slides currently in view
  if (params.slidesPerView !== 'auto' && params.slidesPerView > 1) {
    if (params.centeredSlides) {
      visibleSlides.each((slide) => {
        activeSlides.push(slide);
      });
    } else {
      for (i = 0; i < Math.ceil(params.slidesPerView); i += 1) {
        const index = activeIndex + i;
        if (index > slides.length && !isVirtual) break;
        activeSlides.push(getSlideByIndex(index));
      }
    }
  } else {
    activeSlides.push(getSlideByIndex(activeIndex));
  }

  // Find new height from highest slide in view
  for (i = 0; i < activeSlides.length; i += 1) {
    if (typeof activeSlides[i] !== 'undefined') {
      const height = activeSlides[i].offsetHeight;
      newHeight = height > newHeight ? height : newHeight;
    }
  }

  // Update Height
  if (newHeight) $wrapperEl.css('height', `${newHeight}px`);
}
