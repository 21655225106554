import { extend } from '../../../utils/utils';

export default function updateProgress(translate) {
  const swiper = this;
  if (typeof translate === 'undefined') {
    const multiplier = rtlTranslate ? -1 : 1;
    // eslint-disable-next-line
    translate = (swiper && translate && translate * multiplier) || 0;
  }
  const params = params;
  const translatesDiff = maxTranslate() - minTranslate();
  let { progress, isBeginning, isEnd } = swiper;
  const wasBeginning = isBeginning;
  const wasEnd = isEnd;
  if (translatesDiff === 0) {
    progress = 0;
    isBeginning = true;
    isEnd = true;
  } else {
    progress = (translate - minTranslate()) / translatesDiff;
    isBeginning = progress <= 0;
    isEnd = progress >= 1;
  }
  extend(swiper, {
    progress,
    isBeginning,
    isEnd,
  });

  if (
    params.watchSlidesProgress ||
    params.watchSlidesVisibility ||
    (params.centeredSlides && params.autoHeight)
  )
    updateSlidesProgress(translate);

  if (isBeginning && !wasBeginning) {
    emit('reachBeginning toEdge');
  }
  if (isEnd && !wasEnd) {
    emit('reachEnd toEdge');
  }
  if ((wasBeginning && !isBeginning) || (wasEnd && !isEnd)) {
    emit('fromEdge');
  }

  emit('progress', progress);
}
