
import { getDocument } from 'ssr-window';
import { nextTick, bindModuleMethods } from '../../utils/utils';

const Autoplay = {
  run() {
    const swiper = this;
    const $activeSlideEl = slides.eq(activeIndex);
    let delay = params.autoplay.delay;
    if ($activeSlideEl.attr('data-swiper-autoplay')) {
      delay = $activeSlideEl.attr('data-swiper-autoplay') || params.autoplay.delay;
    }
    clearTimeout(autoplay.timeout);
    autoplay.timeout = nextTick(() => {
      let autoplayResult;
      if (params.autoplay.reverseDirection) {
        if (params.loop) {
          loopFix();
          autoplayResult = slidePrev(params.speed, true, true);
          emit('autoplay');
        } else if (!isBeginning) {
          autoplayResult = slidePrev(params.speed, true, true);
          emit('autoplay');
        } else if (!params.autoplay.stopOnLastSlide) {
          autoplayResult = slideTo(
            slides.length - 1,
            params.speed,
            true,
            true,
          );
          emit('autoplay');
        } else {
          autoplay.stop();
        }
      } else if (params.loop) {
        loopFix();
        autoplayResult = slideNext(params.speed, true, true);
        emit('autoplay');
      } else if (!isEnd) {
        autoplayResult = slideNext(params.speed, true, true);
        emit('autoplay');
      } else if (!params.autoplay.stopOnLastSlide) {
        autoplayResult = slideTo(0, params.speed, true, true);
        emit('autoplay');
      } else {
        autoplay.stop();
      }
      if (params.cssMode && autoplay.running) autoplay.run();
      else if (autoplayResult === false) {
        autoplay.run();
      }
    }, delay);
  },
  start() {
    const swiper = this;
    if (typeof autoplay.timeout !== 'undefined') return false;
    if (autoplay.running) return false;
    autoplay.running = true;
    emit('autoplayStart');
    autoplay.run();
    return true;
  },
  stop() {
    const swiper = this;
    if (!autoplay.running) return false;
    if (typeof autoplay.timeout === 'undefined') return false;

    if (autoplay.timeout) {
      clearTimeout(autoplay.timeout);
      autoplay.timeout = undefined;
    }
    autoplay.running = false;
    emit('autoplayStop');
    return true;
  },
  pause(speed) {
    const swiper = this;
    if (!autoplay.running) return;
    if (autoplay.paused) return;
    if (autoplay.timeout) clearTimeout(autoplay.timeout);
    autoplay.paused = true;
    if (speed === 0 || !params.autoplay.waitForTransition) {
      autoplay.paused = false;
      autoplay.run();
    } else {
      ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
        $wrapperEl[0].addEventListener(event, autoplay.onTransitionEnd);
      });
    }
  },
  onVisibilityChange() {
    const swiper = this;
    const document = getDocument();
    if (document.visibilityState === 'hidden' && autoplay.running) {
      autoplay.pause();
    }
    if (document.visibilityState === 'visible' && autoplay.paused) {
      autoplay.run();
      autoplay.paused = false;
    }
  },
  onTransitionEnd(e) {
    const swiper = this;
    if (!swiper || destroyed || !$wrapperEl) return;
    if (e.target !== $wrapperEl[0]) return;
    ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
      $wrapperEl[0].removeEventListener(event, autoplay.onTransitionEnd);
    });
    autoplay.paused = false;
    if (!autoplay.running) {
      autoplay.stop();
    } else {
      autoplay.run();
    }
  },
  onMouseEnter() {
    const swiper = this;
    if (params.autoplay.disableOnInteraction) {
      autoplay.stop();
    } else {
      autoplay.pause();
    }

    ['transitionend', 'webkitTransitionEnd'].forEach((event) => {
      $wrapperEl[0].removeEventListener(event, autoplay.onTransitionEnd);
    });
  },
  onMouseLeave() {
    const swiper = this;
    if (params.autoplay.disableOnInteraction) {
      return;
    }
    autoplay.paused = false;
    autoplay.run();
  },
  attachMouseEvents() {
    const swiper = this;
    if (params.autoplay.pauseOnMouseEnter) {
      $el.on('mouseenter', autoplay.onMouseEnter);
      $el.on('mouseleave', autoplay.onMouseLeave);
    }
  },
  detachMouseEvents() {
    const swiper = this;
    $el.off('mouseenter', autoplay.onMouseEnter);
    $el.off('mouseleave', autoplay.onMouseLeave);
  },
};

export default {
  name: 'autoplay',
  params: {
    autoplay: {
      enabled: false,
      delay: 3000,
      waitForTransition: true,
      disableOnInteraction: true,
      stopOnLastSlide: false,
      reverseDirection: false,
      pauseOnMouseEnter: false,
    },
  },
  create() {
    const swiper = this;
    bindModuleMethods(swiper, {
      autoplay: {
        ...Autoplay,
        running: false,
        paused: false,
      },
    });
  },
  on: {
    init(swiper) {
      if (params.autoplay.enabled) {
        autoplay.start();
        const document = getDocument();
        document.addEventListener('visibilitychange', autoplay.onVisibilityChange);
        autoplay.attachMouseEvents();
      }
    },
    beforeTransitionStart(swiper, speed, internal) {
      if (autoplay.running) {
        if (internal || !params.autoplay.disableOnInteraction) {
          autoplay.pause(speed);
        } else {
          autoplay.stop();
        }
      }
    },
    sliderFirstMove(swiper) {
      if (autoplay.running) {
        if (params.autoplay.disableOnInteraction) {
          autoplay.stop();
        } else {
          autoplay.pause();
        }
      }
    },
    touchEnd(swiper) {
      if (
        params.cssMode &&
        autoplay.paused &&
        !params.autoplay.disableOnInteraction
      ) {
        autoplay.run();
      }
    },
    destroy(swiper) {
      autoplay.detachMouseEvents();
      if (autoplay.running) {
        autoplay.stop();
      }
      const document = getDocument();
      document.removeEventListener('visibilitychange', autoplay.onVisibilityChange);
    },
  },
};
