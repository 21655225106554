import { getDocument } from 'ssr-window';

import onTouchStart from './onTouchStart';
import onTouchMove from './onTouchMove';
import onTouchEnd from './onTouchEnd';
import onResize from './onResize';
import onClick from './onClick';
import onScroll from './onScroll';

let dummyEventAttached = false;
function dummyEventListener() {}

function attachEvents() {
  const swiper = this;
  const document = getDocument();
  const { params, touchEvents, el, wrapperEl, device, support } = swiper;

  onTouchStart = onTouchStart.bind(swiper);
  onTouchMove = onTouchMove.bind(swiper);
  onTouchEnd = onTouchEnd.bind(swiper);
  if (params.cssMode) {
    onScroll = onScroll.bind(swiper);
  }

  onClick = onClick.bind(swiper);

  const capture = !!params.nested;

  // Touch Events
  if (!support.touch && support.pointerEvents) {
    el.addEventListener(touchEvents.start, onTouchStart, false);
    document.addEventListener(touchEvents.move, onTouchMove, capture);
    document.addEventListener(touchEvents.end, onTouchEnd, false);
  } else {
    if (support.touch) {
      const passiveListener =
        touchEvents.start === 'touchstart' && support.passiveListener && params.passiveListeners
          ? { passive: true, capture: false }
          : false;
      el.addEventListener(touchEvents.start, onTouchStart, passiveListener);
      el.addEventListener(
        touchEvents.move,
        onTouchMove,
        support.passiveListener ? { passive: false, capture } : capture,
      );
      el.addEventListener(touchEvents.end, onTouchEnd, passiveListener);
      if (touchEvents.cancel) {
        el.addEventListener(touchEvents.cancel, onTouchEnd, passiveListener);
      }
      if (!dummyEventAttached) {
        document.addEventListener('touchstart', dummyEventListener);
        dummyEventAttached = true;
      }
    }
    if (
      (params.simulateTouch && !device.ios && !device.android) ||
      (params.simulateTouch && !support.touch && device.ios)
    ) {
      el.addEventListener('mousedown', onTouchStart, false);
      document.addEventListener('mousemove', onTouchMove, capture);
      document.addEventListener('mouseup', onTouchEnd, false);
    }
  }
  // Prevent Links Clicks
  if (params.preventClicks || params.preventClicksPropagation) {
    el.addEventListener('click', onClick, true);
  }
  if (params.cssMode) {
    wrapperEl.addEventListener('scroll', onScroll);
  }

  // Resize handler
  if (params.updateOnWindowResize) {
    on(
      device.ios || device.android
        ? 'resize orientationchange observerUpdate'
        : 'resize observerUpdate',
      onResize,
      true,
    );
  } else {
    on('observerUpdate', onResize, true);
  }
}

function detachEvents() {
  const swiper = this;
  const document = getDocument();

  const { params, touchEvents, el, wrapperEl, device, support } = swiper;

  const capture = !!params.nested;

  // Touch Events
  if (!support.touch && support.pointerEvents) {
    el.removeEventListener(touchEvents.start, onTouchStart, false);
    document.removeEventListener(touchEvents.move, onTouchMove, capture);
    document.removeEventListener(touchEvents.end, onTouchEnd, false);
  } else {
    if (support.touch) {
      const passiveListener =
        touchEvents.start === 'onTouchStart' && support.passiveListener && params.passiveListeners
          ? { passive: true, capture: false }
          : false;
      el.removeEventListener(touchEvents.start, onTouchStart, passiveListener);
      el.removeEventListener(touchEvents.move, onTouchMove, capture);
      el.removeEventListener(touchEvents.end, onTouchEnd, passiveListener);
      if (touchEvents.cancel) {
        el.removeEventListener(touchEvents.cancel, onTouchEnd, passiveListener);
      }
    }
    if (
      (params.simulateTouch && !device.ios && !device.android) ||
      (params.simulateTouch && !support.touch && device.ios)
    ) {
      el.removeEventListener('mousedown', onTouchStart, false);
      document.removeEventListener('mousemove', onTouchMove, capture);
      document.removeEventListener('mouseup', onTouchEnd, false);
    }
  }
  // Prevent Links Clicks
  if (params.preventClicks || params.preventClicksPropagation) {
    el.removeEventListener('click', onClick, true);
  }

  if (params.cssMode) {
    wrapperEl.removeEventListener('scroll', onScroll);
  }

  // Resize handler
  off(
    device.ios || device.android
      ? 'resize orientationchange observerUpdate'
      : 'resize observerUpdate',
    onResize,
  );
}

export default {
  attachEvents,
  detachEvents,
};
