export default function translateTo(
  translate = 0,
  speed = this.params.speed,
  runCallbacks = true,
  translateBounds = true,
  internal,
) {
  const swiper = this;

  const { params, wrapperEl } = swiper;

  if (animating && params.preventInteractionOnTransition) {
    return false;
  }

  const minTranslate = minTranslate();
  const maxTranslate = maxTranslate();
  let newTranslate;
  if (translateBounds && translate > minTranslate) newTranslate = minTranslate;
  else if (translateBounds && translate < maxTranslate) newTranslate = maxTranslate;
  else newTranslate = translate;

  // Update progress
  updateProgress(newTranslate);

  if (params.cssMode) {
    const isH = isHorizontal();
    if (speed === 0) {
      wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = -newTranslate;
    } else {
      // eslint-disable-next-line
      if (wrapperEl.scrollTo) {
        wrapperEl.scrollTo({
          [isH ? 'left' : 'top']: -newTranslate,
          behavior: 'smooth',
        });
      } else {
        wrapperEl[isH ? 'scrollLeft' : 'scrollTop'] = -newTranslate;
      }
    }
    return true;
  }

  if (speed === 0) {
    setTransition(0);
    setTranslate(newTranslate);
    if (runCallbacks) {
      emit('beforeTransitionStart', speed, internal);
      emit('transitionEnd');
    }
  } else {
    setTransition(speed);
    setTranslate(newTranslate);
    if (runCallbacks) {
      emit('beforeTransitionStart', speed, internal);
      emit('transitionStart');
    }
    if (!animating) {
      animating = true;
      if (!onTranslateToWrapperTransitionEnd) {
        onTranslateToWrapperTransitionEnd = function transitionEnd(e) {
          if (!swiper || destroyed) return;
          if (e.target !== this) return;
          $wrapperEl[0].removeEventListener(
            'transitionend',
            onTranslateToWrapperTransitionEnd,
          );
          $wrapperEl[0].removeEventListener(
            'webkitTransitionEnd',
            onTranslateToWrapperTransitionEnd,
          );
          onTranslateToWrapperTransitionEnd = null;
          delete onTranslateToWrapperTransitionEnd;
          if (runCallbacks) {
            emit('transitionEnd');
          }
        };
      }
      $wrapperEl[0].addEventListener(
        'transitionend',
        onTranslateToWrapperTransitionEnd,
      );
      $wrapperEl[0].addEventListener(
        'webkitTransitionEnd',
        onTranslateToWrapperTransitionEnd,
      );
    }
  }

  return true;
}
